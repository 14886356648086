<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Grupos"
          item-text="nombre"
          item-value="id"
          :items="grupos"
          v-model="grupoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Estudiante"
          item-text="nombreCompleto"
          item-value="estudianteId"
          :items="estudiantes"
          v-model="estudianteId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="observaciones"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> Observador </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="deshabilitar"
                  >
                    Adicionar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="editedItem.incluirBoletin"
                            label="Incluir en boletin"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            counter
                            label="Observación"
                            v-model="editedItem.descripcion"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="deshabilitar"
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    editedIndex: -1,
    dialog: false,
    incluirB: false,
    periodoId: 0,
    grupoId: 0,
    estudianteId: "",
    periodos: [],
    grupos: [],
    estudiantes: [],
    observaciones: [],
    observacion: "",
    headers: [
      { text: "Fecha", value: "fechaRegistro" },
      { text: "Docente", value: "nombreDocente" },
      { text: "Observación", value: "descripcion" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    editedItem: {
      id: 0,
      descripcion: "",
      incluirBoletin: false,
    },
    defaultItem: {
      id: 0,
      descripcion: "",
      incluirBoletin: false,
    },
  }),
  computed: {
    deshabilitar() {
      return !(
        this.estudianteId !== "" &&
        this.periodoId > 0 &&
        this.grupoId > 0
      );
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions([
      "GetPeriodos",
      "GetGruposDocente",
      "GetAlumnosGrupoPerido",
      "RegistrarObservador",
      "GetEstudianteObservadorPeriodo",
      "GetEstudianteObservador",
      "UpdateObservador",
      "ModificarMensaje"
    ]),
    initialize() {
      this.GetPeriodos().then((x) => (this.periodos = x.data));
    },
    editItem(item) {
      this.editedIndex = this.observaciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save() {
      if (this.editedIndex > -1) {
        let index = this.editedIndex;
        let datos = {};
        datos.id = this.editedItem.id;
        datos.descripcion = this.editedItem.descripcion;
        datos.fechaRegistro = new Date();
        datos.periodoId = this.periodoId;
        datos.estudianteId = this.estudianteId;
        datos.incluirBoletin = this.editedItem.incluirBoletin;
        this.UpdateObservador(datos).then((x) => {
          Object.assign(this.observaciones[index], x.data);
           this.ModificarMensaje({ texto: "Actualización Exitoso!" });
          this.close();
        });
      } 
      else 
      {
        let datos = {};
        datos.id = 0;
        datos.descripcion = this.editedItem.descripcion;
        datos.fechaRegistro = new Date();
        datos.periodoId = this.periodoId;
        datos.estudianteId = this.estudianteId;
        datos.incluirBoletin = this.editedItem.incluirBoletin;
        this.RegistrarObservador(datos).then((x) => {
          const resultado = x.data;
          if (!!resultado) {
            this.observaciones.push(x.data);
             this.ModificarMensaje({ texto: "Registro Exitoso!" });
          }
          this.close();
        });
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    periodoId(nuevoValor) {
      if (nuevoValor > 0) {
        this.grupoId = 0;
        this.estudianteId = "";
        this.GetGruposDocente(this.periodoId).then(
          (x) => (this.grupos = x.data)
        );
      } else {
        this.grupos = [];
        this.estudiantes = [];
        this.estudianteId = "";
      }
    },
    grupoId(nuevoValor) {
      if (nuevoValor > 0) {
        let datos = {};
        datos.grupo = nuevoValor;
        datos.periodo = this.periodoId;
        this.GetAlumnosGrupoPerido(datos).then(
          (x) => (this.estudiantes = x.data)
        );
      } else {
        this.estudiantes = [];
        this.estudianteId = "";
      }
    },
    estudianteId(nuevoValor) {
      if (nuevoValor === "") {
        this.estudiantes = [];
      } else {
        let datos = {};
        datos.estudiante = nuevoValor;
        datos.periodo = this.periodoId;
        this.GetEstudianteObservadorPeriodo(datos).then(
          (x) => (this.observaciones = x.data)
        );
      }
    },
  },
};
</script>