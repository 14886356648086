<template>
    <RegistroObservador></RegistroObservador>
</template>
<script>
import RegistroObservador from "@/components/RegistroObservador.vue"
export default {
    components:{
        RegistroObservador
    }
}
</script>